<!--
 * @Author: wangshuaiwei
 * @Date: 2021-12-14 10:34:21
 * @LastEditors: wangshuaiwei
 * @LastEditTime: 2022-03-02 10:30:03
 * Description tableTemplate组件:
-->
<template>
  <div class="enterpriseInfo">
    <div>
      <tableTemplate
        class="table"
        :searchSelectList="enterpriseList"
        @searchFormType="searchFormType"
        :searchListInfo="searchListInfo"
        @searchBut="searchBut"
        :loading="loading"
        :data="tableData"
        :columns="columns"
        @addClick="addClick"
        addName="添加企业"
      >
        <template slot="avatar" slot-scope="scope">
          <div slot="avatar" class="operation">
            <span @click="enterpriseInfo(scope.data.row)">详情</span>
            <span @click="eait(scope.data.row)">编辑</span>
            <span @click="delect(scope.data.row)">删除</span>
          </div>
        </template>
      </tableTemplate>
      <page @changePage="eventPage" @changeSize="eventPage" class="pageStyle" :pagination="pagination" />
      <!-- 添加 -->
      <el-dialog :title="titles" :destroy-on-close="true" :visible.sync="dialogVisible" width="30%" id="dialogDetail">
        <el-form ref="addForm" :rules="rules" :model="addForm" label-width="80px">
          <el-form-item label="企业名称" prop="name">
            <el-input :disabled="infoFrom" v-model="addForm.name"></el-input>
          </el-form-item>
          <el-form-item label="法人代表" prop="legalRepresentative">
            <el-input :disabled="infoFrom" v-model="addForm.legalRepresentative"></el-input>
          </el-form-item>
          <el-form-item label="企业类型" prop="enterpriseType">
            <el-select :disabled="infoFrom" v-model="addForm.enterpriseType" placeholder="请选择企业类型">
              <el-option v-for="self in enterpriseList" :key="self.id" :label="self.name" :value="self.name"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="产业类型" prop="industryType">
            <el-select :disabled="infoFrom" v-model="addForm.industryType" placeholder="请选择产业类型">
              <el-option v-for="item in industryList" :key="item.id" :label="item.name" :value="item.name"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="产业地址" prop="address">
            <el-input :disabled="infoFrom" v-model="addForm.address"></el-input>
          </el-form-item>
          <el-form-item label="手机号" prop="phoneNum">
            <el-input :disabled="infoFrom" v-model="addForm.phoneNum"></el-input>
          </el-form-item>
          <el-form-item label="邮箱" prop="mailBox">
            <el-input :disabled="infoFrom" v-model="addForm.mailBox"></el-input>
          </el-form-item>
          <el-form-item label="产业简介" prop="introduction">
            <el-input :disabled="infoFrom" v-model="addForm.introduction"></el-input>
          </el-form-item>
          <el-form-item label="企业荣誉" v-if="!infoFrom">
            <el-upload
              class="upload-demo"
              action="/imgUpdata"
              :limit="2"
              :file-list="fileList"
              :on-change="handleChange"
              :before-upload="beforeAvatarUpload"
              :on-exceed="handleExceed"
              :on-success="handleAvatarSuccess"
              :on-remove="onRemove"
            >
              <div class="upFlieBox"><i class="el-icon-upload"></i><span>点击上传</span></div>
            </el-upload>
          </el-form-item>
          <el-form-item class="isUpload" label="营业执照" v-if="!infoFrom">
            <el-upload class="avatar-uploader" action="/imgUpdata" :on-success="handleAvatarSuccess2" :before-upload="beforeAvatarUpload2" :show-file-list="false">
              <img v-if="addForm.businessLicenseImg" :src="addForm.businessLicenseImg" class="businessImg  avatar" />
              <i v-else class="el-icon-plus avatar-uploader-icon uploadImg"></i>
            </el-upload>
          </el-form-item>
          <el-form-item label="上传视频" v-if="!infoFrom">
            <el-upload
              class="upload-demo"
              action="/imgUpdata"
              :limit="1"
              :file-list="fileList3"
              :on-change="handleChange3"
              :before-upload="beforeAvatarUpload3"
              :on-exceed="handleExceed3"
              :on-success="handleAvatarSuccess3"
              :on-remove="onRemove3"
            >
              <div class="upFlieBox"><i class="el-icon-upload"></i><span>点击上传</span></div>
            </el-upload>
          </el-form-item>
          <el-form-item label="企业荣誉" v-if="infoFrom">
            <div class="imgStyle">
              <img v-for="(item,i) in honorList" :key="i" :src="item.imgUrl" alt="">
            </div>
          </el-form-item>
          <el-form-item label="营业执照" v-if="infoFrom">
            <div class="imgStyle">
              <img v-if="licenseList" :src="licenseList" alt="">
              <span v-else>暂无上传</span>
            </div>
          </el-form-item>
          <el-form-item label="上传视频" v-if="infoFrom">
            <!-- <ul>
              <li v-for="(item,p) in videoList" :key="p">{{item.name}}</li>
            </ul> -->
            <video class="videoStyle" controls>
              <source v-if="videoList[0]" :src="videoList[0].videoUrl" type="video/mp4">
            </video>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer" v-if="!infoFrom">
          <el-button size="small" @click="dialogVisible = false">取 消</el-button>
          <el-button class="butColor" size="small" type="primary" @click="saveFrom(addForm, 'addForm')">确 定</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import tableTemplate from '@/components/soureUnit/tableTemplate.vue';
import page from '@/components/page.vue';
import { required } from '@/utils/required.js';
export default {
  components: {
    tableTemplate,
    page,
  },
  data() {
    return {
      columns: [
        { label: 'ID', prop: 'id', align: 'center' },
        { label: '企业名称', prop: 'name', align: 'center' },
        // { label: '所属区域', prop: 'area', align: 'center' },
        { label: '地址', prop: 'address', align: 'center' },
        { label: '负责人', prop: 'legalRepresentative', align: 'center' },
        { label: '联系方式', prop: 'phoneNum', align: 'center' },
        { label: '操作', prop: 'avatar', align: 'center', __slotName: 'avatar' },
      ],
      tableData: [],
      addForm: { businessLicenseImg: '' },
      dialogVisible: false,
      titles: '新建',
      pagination: {
        totalRow: 0,
        page: 1,
        size: 8,
      },
      fileList: [],
      fileList3: [],
      seceltModel: true,
      rules: required,
      enterpriseList: [
        //企业信息
        { name: '有限公司', value: 0 },
        { name: '无限公司', value: 1 },
      ],
      industryList: [
        //产业类型
        { name: '第一产业', value: 0 },
        { name: '第二产业', value: 1 },
        { name: '第三产业', value: 2 },
      ],
      loading: true,
      searchListInfo: {
        name: '关键字',
        type: '企业类型',
      },
      infoFrom: false,
      honorList:[],
      honor: false,
      videoList:[],
      licenseList:[]
    };
  },
  mounted() {
    this.requestLsit();
  },
  methods: {
    addClick() {
      this.dialogVisible = true;
      this.titles = '新建';
      this.infoFrom = false;
      this.$nextTick(() => {
        if (this.$refs['addForm'] !== undefined) {
          this.$nextTick(() => {
            this.addForm = { businessLicenseImg: '' };
            this.$refs['addForm'].resetFields();
            this.fileList = [];
            this.fileList3 = [];
          });
        }
      });
    },
    exitLoading() {
      setTimeout(() => {
        this.loading = false;
      }, 500);
    },
    requestLsit(name, enterpriseType) {
      // 企业信息
      this.$get('/enterpriseManage/querryEnterprise', { page: this.pagination.page, size: this.pagination.size, name: name || '', enterpriseType: enterpriseType || '' })
        .then((res) => {
          if (res.data.state == 'success') {
            let { datas } = res.data;
            this.pagination.totalRow = res.data.count;
            this.tableData = datas;
            this.exitLoading();
          }
        })
        .catch((err) => {
          this.exitLoading();
          throw err;
        });
    },
    // 保存
    saveFrom(data, dom) { 
      let url;
      let uploadImg = [];
      let uploadVideo = [];

      this.$refs[dom].validate((valid) => {
        if (valid) {
          let demoUrl = 'https://public.half-half.cn/';
          // if (this.fileList.length == 0) return this.$message.info('请上传荣誉照图片');
          // if (!this.addForm.businessLicenseImg) return this.$message.info('请上传营业执照');
          // if (this.fileList3.length == 0) return this.$message.info('请上传视频文件');
          this.fileList.forEach((v) => uploadImg.push({ name: v.name, imgUrl: demoUrl + v.response.data.fileName, response: v.response }));
          this.fileList3.forEach((v) => uploadVideo.push({ name: v.name, videoUrl: demoUrl + v.response.data.fileName, response: v.response }));
          data.honorImg = JSON.stringify(uploadImg);
          data.uploadVideo = JSON.stringify(uploadVideo);
          if (this.titles == '新建') {
            url = '/enterpriseManage/addEnterprise';
          } else if (this.titles == '修改') {
            url = '/enterpriseManage/updateEnterprise';
            data.enterpriseId = data.id;
            delete data.id;
          }
          this.$post(url, data).then((res) => {
            if (res.data.state == 'success') {
              this.dialogVisible = false;
              this.$message.success(res.data.msg);
              this.requestLsit();
            }
          });
        }
      });
    },
    // 修改
    eait(data) {
      //console.log(data)
      this.titles = '修改';
      this.dialogVisible = true;
      this.infoFrom = false;
      this.addForm = JSON.parse(JSON.stringify(data));
      this.fileList = [];
      this.fileList3 = [];
      if (data.honorImg && this.isJSON_test(data.honorImg)) this.fileList = JSON.parse(data.honorImg);
      if (data.uploadVideo && this.isJSON_test(data.uploadVideo)) {
        this.fileList3 = JSON.parse(data.uploadVideo);
      }
    },
    enterpriseInfo(data) {
      
      this.titles = '详情';
      this.dialogVisible = true;
      this.infoFrom = true;
      this.addForm = JSON.parse(JSON.stringify(data));
      this.videoList =JSON.parse(data.uploadVideo);
      this.honorList =JSON.parse(data.honorImg);
      this.licenseList =data.businessLicenseImg;
      //console.log(data);
      if (data.honorImg && this.isJSON_test(data.honorImg)) this.fileList = JSON.parse(data.honorImg);
      if (data.uploadVideo && this.isJSON_test(data.uploadVideo)) {
        this.fileList3 = JSON.parse(data.uploadVideo);
      }
    },
    isJSON_test(str) {
      if (typeof str == 'string') {
        try {
          var obj = JSON.parse(str);
          //console.log(obj);
          return true;
        } catch (e) {
          //console.log('error：' + str + '!!!' + e);
          return false;
        }
      }
    },
    //删除
    delect(data) {
      this.$confirm(`此操作将永久删除 ${data.name} 信息, 是否继续?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.$get('/enterpriseManage/delEnterprise', { enterpriseId: data.id }).then((res) => {
            if (res.data.state == 'success') {
              this.requestLsit();
              this.loading = true;
              this.$message.success(res.data.msg);
            }
          });
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除',
          });
        });
    },
    indexMethod(index) {
      return index + 1 + (this.curPage - 1) * this.pageSize;
    },
    //
    searchBut(data) {
      this.requestLsit(data.name);
    },
    searchFormType(val) {
      this.requestLsit('', val);
    },
    eventPage() {
      this.requestLsit();
    },
    // refresh(){
    //   this.loading = true;
    //   this.requestLsit();
    // },
    // removeForm() {
    //   this.loading = true;
    //   this.requrstList();
    // },
    // 企业荣誉上传
    handleChange(file, fileList) {
      this.fileList = fileList.slice(-2);
    },
    beforeAvatarUpload(file) {
      let { name } = file;
      name = name.split('.')[1];
      if (name == 'jpg' || name == 'png') {
        // this.uploadState = 0;
        return true;
      }
      this.$message.info('请检查上传格式！');
      this.fileList = [];
      return false;
    },
    handleExceed() {
      this.$message.info('只能上传两个文件');
    },
    handleAvatarSuccess(res, file) {
      this.addForm.honorImg = 'https://public.half-half.cn/' + res.data.fileName;
      //   //console.log(this.fileList);
    },
    onRemove(file, fileList) {
    //   this.addFormModel.url = '';
      this.fileList=[];
    },
    // 营业执照上传
    beforeAvatarUpload2(file) {
      let { name } = file;
      name = name.split('.')[1];
      if (name == 'jpg' || name == 'png') {
        // this.uploadState = 0;
        return true;
      }
      this.$message.info('请检查上传格式！');
      this.fileList2 = [];
      return false;
    },
    handleAvatarSuccess2(res, file) {
      this.addForm.businessLicenseImg = 'https://public.half-half.cn/' + res.data.fileName;
    },
    // 视频上传
    handleChange3(file, fileList) {
      this.fileList3 = fileList.slice(-2);
    },
    beforeAvatarUpload3(file) {
      let { name } = file;
      name = name.split('.')[1];
      if (name == 'mp4') {
        return true;
      }
      this.$message.info('请检查上传格式！');
      this.fileList3 = [];
      return false;
    },
    handleExceed3() {
      this.$message.info('只能上传一个文件');
    },
    handleAvatarSuccess3(res, file) {
      this.addForm.uploadVideo = 'https://public.half-half.cn/' + res.data.fileName;
    },
    onRemove3(file, fileList) {
    //   this.addFormModel.uploadVideo = '';
      this.fileList3=[];
    },
  },
};
</script>
<style scoped lang="less">
@import "~@/style/colorPublic";
.butColor{
    // background: @but_color;
    background: #409eff !important;
}
.operation {
  display: flex;
  justify-content: space-evenly;
  span {
    cursor: pointer;
  }
}
.enterpriseInfo /deep/ .el-dialog__title {
  font-size: 1.5vh;
}
.table {
  height: 82vh;
  overflow-y: auto;
  .storkBut{
    background: #409eff !important;
  }
}

// 营业执照
.isUpload .avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.isUpload .avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.isUpload .avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.isUpload .businessImg {
  width: 15vh;
  height: 15vh;
}
.uploadImg {
  display: block;
  font-size: 4.5vh;
  display: block;
  border: 1px dashed #ccc;
}
.enterpriseInfo {
  background: #fff;
  padding: 2vh;
  box-sizing: border-box;
}
.imgStyle{
  display: flex;
  justify-content: space-between;
  img{
    width: 150px;
    height: 150px;
  }
}
.videoStyle{
  width: 300px;
  height: 200px;
}


</style>
<style>
  #dialogDetail .el-icon-close:before{
    color: #333!important;
  }
  .el-pagination.is-background .el-pager li:not(.disabled).active{
    background: none !important;
    color: #409eff;
  }
</style>
